body{
  background: #f7f8fa;
}

.sidebar-wrapper {
  background-clip: padding-box;
  background-color: #f7f8fa;
  border-right: 1px solid #e2e3e4;
  height: 100%;
  left: 0;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  transition: all .2s;
  width: 260px;
  z-index: 16;
}


/* td{

} */

.btn_quantity{
  border-radius: 25px !important;
  height: 30px !important;
  width: 30px !important;
}

.quantityModelInput{
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.336);
  padding-left: 1rem;
  height: 3rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 5px;
}

.sidebar-wrapper .metismenu {
  background: 0 0;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  padding: 10px;
}


.sidebar-wrapper ul {
  background: 0 0;
  list-style: none;
  margin: 0;
  padding: 0;
}


.sidebar-wrapper .sidebar-header {
  align-items: center;
  background: #f7f8fa;
  background-clip: padding-box;
  border-bottom: 1px solid #e4e4e4;
  border-right: 1px solid #e2e3e4;
  bottom: 0;
  display: flex;
  height: 60px;
  padding: 0 15px;
  position: fixed;
  top: 0;
  transition: all .2s;
  width: 260px;
  z-index: 5;
}



.sidebar-wrapper .metismenu li+li, .sidebar-wrapper .metismenu li:first-child {
  margin-top: 5px;
}


.sidebar-wrapper .metismenu>li {
  display: flex;
  flex-direction: column;
  position: relative;
}


*, :after, :before {
  box-sizing: border-box;
}


.sidebar-wrapper .metismenu a {
  align-items: center;
  border-left: 4px solid hsla(0,0%,100%,0);
  border-radius: 4px;
  color: #5f5f5f;
  display: flex;
  font-size: 15px;
  justify-content: left;
  letter-spacing: .5px;
  outline-width: 0;
  overflow: hidden;
  padding: 10px 15px;
  position: relative;
  text-overflow: ellipsis;
  transition: all .2s ease-out;
}


.metismenu .accordion-collapse {
  margin: 0;
}


.collapse:not(.show) {
  display: none;
}

.sidebar-wrapper .metismenu li+li, .sidebar-wrapper .metismenu li:first-child {
  margin-top: 5px;
}


.top-header .navbar {
  background-clip: padding-box;
  background-color: #f7f8fa;
  border-bottom: 1px solid #e2e3e4;
  height: 60px;
  left: 260px;
  padding: 0 1.5rem;
  position: fixed;
  right: 0;
  top: 0;
  transition: all .2s;
  z-index: 10;
}


.page-content {
  margin-left: 10px;
  margin-top: 60px;
  padding: 1.5rem;
  transition: all .2s;
}

.radius-10 {
  border-radius: 10px;
}

.card {
  word-wrap: break-word;
  background-clip: initial;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.125);
  display: flex;
  flex-direction: column;
  min-width: 0;
  position: relative;
  margin-bottom: 20px;
}



.authentication-card {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  margin: auto;
  max-width: 60rem;
}



.gap-2 {
  gap: 8px;
}


.h1, h1 {
  font-size: 20px;
}


.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: var(--title-color);
  line-height: 1.2;
}


.fz-14 {
  font-size: 14px!important;
}


.badge-soft-dark {
  background: #e0e7ee;
}
.radius-50 {
  border-radius: 50px!important;
}

.main .content {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
  margin-top: 84px !important;
}

.container-fluid, .container-xxl {
  padding-right: 2rem;
  padding-left: 2rem;
}

.card {
  border-color: #f4f4f4;
  box-shadow: 0 5px 10px rgba(51,66,87,.05);
  transition: all .3s ease;
}

.form-control {
  border-color: #d0dbe9;
}


.form-control {
  display: block;
  width: 100%;
  height: calc(1.6em + 1.21875rem);
  padding: .54688rem .875rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.6;
  color: #1e2022;
  background-color: #fff;
  background-clip: padding-box;
  border: .0625rem solid #e7eaf3;
  border-radius: .3125rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}


select {
  background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #ddd;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 26px!important;
  background-size: 10px;
  background-position: calc(100% - 8px);
}

.form-floating {
  position: relative;
}

.form-floating>.form-control:not(:placeholder-shown) {
  padding-top: 1rem;
  padding-bottom: .625rem;
}

.form-floating>label, .form-floating>.form-control, .form-floating>.form-control-plaintext {
  padding: .625rem 1.25rem;
}

.form-control {
  border-color: #d0dbe9;
}

.btn-block {
  display: block;
  width: 100%;
}



.light-bg {
  background-color: #fcfcfc;
}
.pb-4, .py-4 {
  padding-bottom: 1.5rem !important;
}
.pt-4, .py-4 {
  padding-top: 1.5rem !important;
}
.pl-3, .px-3 {
  padding-left: 1rem !important;
}
.pr-3, .px-3 {
  padding-right: 1rem !important;
}
*, ::after, ::before {
  box-sizing: border-box;
}


.input-group-custom {
  border: 1px solid var(--c1);
  border-radius: 5px;
}
.input-group-merge {
  position: relative;
}
.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}
*, ::after, ::before {
  box-sizing: border-box;
}
.input-group-merge .input-group-prepend {
  left: 0;
}
.input-group-merge .input-group-append, .input-group-merge .input-group-prepend {
  position: absolute;
  top: 0;
  bottom: 0;
}
.input-group .input-group-append, .input-group .input-group-prepend {
  z-index: 4;
}
.input-group-prepend {
  margin-right: -0.0625rem;
}
.input-group-append, .input-group-prepend {
  display: -ms-flexbox;
  display: flex;
}
*, ::after, ::before {
  box-sizing: border-box;
}


.input-group-merge .input-group-prepend .input-group-text:first-child {
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group-merge .input-group-prepend .input-group-text {
  padding: 0 0 0 0.875rem;
}
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group > .input-group-append:last-child > .input-group-text:not(:last-child), .input-group > .input-group-append:not(:last-child) > .btn, .input-group > .input-group-append:not(:last-child) > .input-group-text, .input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group-merge .input-group-text {
  z-index: 4;
  border: none;
}
.input-group-text {
  border-color: #e7eaf3;
}
.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.54688rem 0.875rem;
  margin-bottom: 0;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.6;
  color: #677788;
  text-align: center;
  white-space: nowrap;
  background-color: transparent;
  border: 0.0625rem solid #e7eaf3;
  border-radius: 0.3125rem;
}
*, ::after, ::before {
  box-sizing: border-box;
}


[class^=tio-], [class*=" tio-"] {
  font-family: the-icon-of!important;
  font-size: 1.125em;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*, ::after, ::before {
  box-sizing: border-box;
}

.tio-search:before {
  content: "\e956";
}
*, ::after, ::before {
  box-sizing: border-box;
}
[class^=tio-], [class*=" tio-"] {
  font-family: the-icon-of!important;
  font-size: 1.125em;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.input-group-merge .custom-select:not(:last-child), .input-group-merge .form-control:not(:last-child) {
  padding-right: 10px;
}
.input-group-merge .custom-select:not(:last-child), .input-group-merge .form-control:not(:last-child) {
  padding-right: 2.625rem;
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}
.input-group-merge .custom-select:not(:first-child), .input-group-merge .form-control:not(:first-child) {
  padding-left: 2.625rem;
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}
.input-group > .custom-select:not(:first-child), .input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-select:not(:last-child), .input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file, .input-group > .custom-select, .input-group > .form-control, .input-group > .form-control-plaintext {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group-custom input, .input-group-custom .btn {
  border-radius: 0;
  border: none;
}


.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
*, ::after, ::before {
  box-sizing: border-box;
}


element.style {
  text-align: left;
}
.table {
  color: var(--title-color);
}
.card-table {
  margin-bottom: 0;
}
.w-100 {
  width: 100% !important;
}
.w-100 {
  width: 100% !important;
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #677788;
}
table {
  border-collapse: collapse;
}
*, ::after, ::before {
  box-sizing: border-box;
}
table {
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border-color: grey;
}


.table .thead-50 {
  block-size: 50px;
}
.text-capitalize {
  text-transform: capitalize !important;
}
*, ::after, ::before {
  box-sizing: border-box;
}
user agent stylesheet
thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}
style attribute {
  text-align: left;
}
.table {
  color: var(--title-color);
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #677788;
}
table {
  border-collapse: collapse;
}
user agent stylesheet
table {
  border-collapse: separate;
  text-indent: initial;
  border-spacing: 2px;
}
.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0.0625rem solid rgba(231, 234, 243, .7);
  border-radius: 0.75rem;
}

.table-align-middle tbody td {
  vertical-align: middle;
}
.table-nowrap td, .table-nowrap th {
  white-space: nowrap;
}
.card-table td, .card-table th {
  padding-right: 1.3125rem;
  padding-left: 1.3125rem;
}
.table-borderless tbody + tbody, .table-borderless td, .table-borderless th, .table-borderless thead th {
  border: 0;
}
.table td, .table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 0.0625rem solid rgba(231, 234, 243, .7);
}
.text-capitalize {
  text-transform: capitalize !important;
}
.text-center {
  text-align: center !important;
}
*, ::after, ::before {
  box-sizing: border-box;
}

.fz-12 {
  font-size: 12px!important;
}
.badge-soft-info {
  color: #00c9db;
  background-color: rgba(0, 201, 219, .05);
}
.badge {
  display: inline-block;
  padding: 0.3125em 0.5em;
  font-size: 75%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.3125rem;
  transition: all .2s ease-in-out;
}
*, ::after, ::before {
  box-sizing: border-box;
}

.toppp{
  margin-top: 85px !important;
}

button, input {
  overflow: visible;
}
button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
*, ::after, ::before {
  box-sizing: border-box;
}
user agent stylesheet
input[type="hidden" i] {
  appearance: none;
  background-color: initial;
  cursor: default;
  display: none !important;
  padding: initial;
  border: initial;
}
user agent stylesheet
input {
  writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: fieldtext;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  appearance: auto;
  -webkit-rtl-ordering: logical;
  cursor: text;
  background-color: field;
  margin: 0em;
  padding: 1px 2px;
  border-width: 2px;
  border-style: inset;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  border-image: initial;
}

.upload-img-view {
  block-size: 185px;
  inline-size: 185px;
  max-inline-size: 185px;
  border-radius: 5px;
  border: 0.5px solid #99a7ba;
  aspect-ratio: 1/1;
  -o-object-fit: cover;
  object-fit: cover;
}
img {
  max-width: 100%;
}
img {
  vertical-align: middle;
  border-style: none;
}
*, ::after, ::before {
  box-sizing: border-box;
}
img {
  overflow-clip-margin: content-box;
  overflow: clip;
}


.form-group {
  margin-bottom: 1.5rem;
}
*, ::after, ::before {
  box-sizing: border-box;
}


.title-color {
  color: var(--title-color);
}
label {
  text-transform: capitalize;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
*, ::after, ::before {
  box-sizing: border-box;
}

label {
  cursor: default;
}


.text-info {
  color: #03b0c0!important;
}

.custom-file {
  cursor: pointer;
}
.text-left {
  text-align: left !important;
}
.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.6em + 1.21875rem);
  margin-bottom: 0;
}
*, ::after, ::before {
  box-sizing: border-box;
}


.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.6em + 1.21875rem);
    margin: 0;
    /* opacity: 0; */
}
button, input {
    overflow: visible;
}
button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
*, ::after, ::before {
    box-sizing: border-box;
}


.avatar-60 {
  width: 60px!important;
  min-width: 60px!important;
  height: 60px!important;
}
.switch, .switcher {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  inline-size: 36px;
  block-size: 18px;
  margin-bottom: 0;
}

.switch .status, .switch_input, .switcher .status, .switcher_input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}


.switch .status:checked~.slider, .switch_input:checked~.switcher_control, .switcher .status:checked~.slider, .switcher_input:checked~.switcher_control {
  background-color: rgb(84, 84, 255);
}
.switch .slider, .switch_control, .switcher .slider, .switcher_control {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  inline-size: 36px;
  block-size: 18px;
  transition: background-color .15s ease-in;
  background-color: #ced7dd;
  border-radius: 50px;
}


.switch .status:checked~.slider:after, .switch_input:checked~.switcher_control:after, .switcher .status:checked~.slider:after, .switcher_input:checked~.switcher_control:after {
  inset-inline-start: 19px;
}
.switch .slider::after, .switch_control::after, .switcher .slider::after, .switcher_control::after {
  content: "";
  position: absolute;
  inset-block-start: 1px;
  inset-inline-start: 1px;
  inline-size: 16px;
  block-size: 16px;
  transition: left .15s ease-in;
  background-color: #fff;
  border-radius: 100%;
}

.switch, .switcher {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  inline-size: 36px;
  block-size: 18px;
  margin-bottom: 0;
}
label {
  text-transform: capitalize;
}

.avatar {
  position: relative;
  display: inline-block;
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 0.3125rem;
}


.badge-soft-danger {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, .1);
}


.badge-soft-success {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, .1);
}

.btn-soft-info {
  color: #00c9db;
  background-color: rgba(0, 201, 219, .1);
  border-color: transparent;
}

thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}


.top-header .navbar .searchbar {
  position: relative;
  width: 30%;
}
*, :after, :before {
  box-sizing: border-box;
}

.top-header .navbar .searchbar .form-control {
  border: 1px solid #e2e3e4;
  border-radius: 30px;
  box-shadow: none;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.top-header .navbar .searchbar .search-close-icon {
  cursor: pointer;
  display: none;
  opacity: .5;
  right: 2rem;
}

.sidebar-wrapper .sidebar-header .logo-icon {
  width: 35px;
}

.sidebar-wrapper .metismenu a {
  align-items: center;
  border-left: 4px solid hsla(0,0%,100%,0);
  border-radius: 4px;
  color: #5f5f5f;
  display: flex;
  font-size: 15px;
  justify-content: left;
  letter-spacing: .5px;
  outline-width: 0;
  overflow: hidden;
  padding: 10px 15px;
  position: relative;
  text-overflow: ellipsis;
  transition: all .2s ease-out;
}

.accordion-button:not(.collapsed) {
  background-color: #ffffff !important;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
  color: #0c63e4;
  border-left: 5px solid #0c63e4 !important;
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
  cursor: pointer;
}


.accordion-button:hover {
  background-color: #ffffff !important;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.3);
  color: #0c63e4;
  border-left: 5px solid #0c63e4 !important;
}


a{
  text-decoration: none;
}


.sidebar-wrapper .metismenu a .menu-title {
  margin-left: 10px;
}

.bi-house-fill::before {
  content: "\f424";
}
[class^="bi-"]::before, [class*=" bi-"]::before {
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



.badge-success {
  color: #fff;
  background-color: #00c9a7;
}

.badge {
  display: inline-block;
  padding: 0.3125em 0.5em;
  font-size: 75%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.3125rem;
  transition: all .2s ease-in-out;
}



.dataTables_filter label{
  background: #1070ff !important;
  color:white;
  padding: 0px 0px 0px 10px;
  height:40px;
  border-radius: 7px;
}


.dataTables_filter input{
  background:white !important;
  height:40px;
}


.dt-button{
  background: #1070ff !important;
  color:white !important; 
  border:none;
  padding:10px;
  border-radius: 7px;

}




.ant-message{
  z-index: 9999999999999999999999;
}